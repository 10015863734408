<template>
  <div class="row">
    <div class="col-12 col-lg-12 px-8">
      <div class="card">
        <div class="card-header position-relative min-vh-25 mb-7">
          <div class="">
          <img style="width: 100%; max-height: 320px; position: absolute; left: 0px; top: 0px;" src="https://easyretro.io/_nuxt/img/board-example.2070d78.webp" alt="">
        </div>
        <div class="avatar avatar-profile">
          <img style="width: 100px; height: 100px; position: absolute" class="rounded-circle img-thumbnail shadow-sm"
          src="https://easyretro.io/_nuxt/d7730f2734ccfca168d6b703c133b965.svg" alt="" />
        </div>
      </div>
      <div class="mt-4">

      </div>
      <div class="mt-4">

      </div>
      <div class="mt-4">

      </div>
      <div class="mt-4">

      </div>
      <div class="card-body mt-4">
        <div class="row">
          <div class="col-12 col-lg-4 text-left">
            <h4 class="mt-2 mb-1 f-raleway fw-500 fs-14pt">
              EASY RETRO
              <small class="fas fa-check-circle text-primary ml-1" data-toggle="tooltip" data-placement="right" title="Verified" data-fa-transform="shrink-4 down-2"></small>
            </h4>
            <h5 class="mt-2 mb-0 fs-11pt f-montserrat fw-300">https://easyretro.io/</h5>
            <hr class="border-dashed my-4 d-lg-none" />
          </div>
          <div class="col pl-2 pl-lg-4 text-left">
          </div>
          <div class="col pl-2 pl-lg-4 text-right">
            <button class="btn btn-falcon-primary btn-sm px-3" type="button">Following</button>
            <button class="btn btn-falcon-default btn-sm px-3 ml-2" type="button">Message</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <hr class="mt-0 mb-1">
          <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
            <v-tab v-for="item in items" :key="item">
              <span class="f-raleway fw-400">{{ item }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">

            <v-tab-item>
              <v-card color="basil" flat>
                <v-card-text>
                  <div class="row">
                    <div class="col-12 col-lg-12">
                      <v-card>
                        <v-card-title>Intro</v-card-title>
                        <v-card-text>
                          <p class="mb-0 text-1000 text-justify">Dedicated, passionate, and accomplished Full Stack Developer with 9+ years of progressive experience working as an Independent Contractor for Google and developing and growing my educational social network that helps others learn programming, web design, game development, networking.</p>
                          <div class="collapse show text-justify" id="profile-intro">
                            <p class="mt-3 text-1000">I’ve acquired a wide depth of knowledge and expertise in using my technical skills in programming, computer science, software development, and mobile app development to developing solutions to help organizations increase productivity, and accelerate business performance. </p>
                            <p class="text-1000">It’s great that we live in an age where we can share so much with technology but I’m but I’m ready for the next phase of my career, with a healthy balance between the virtual world and a workplace where I help others face-to-face.</p>
                            <p class="text-1000 mb-0">There’s always something new to learn, especially in IT-related fields. People like working with me because I can explain technology to everyone, from staff to executives who need me to tie together the details and the big picture. I can also implement the technologies that successful projects need.</p>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card color="basil" flat>
                <v-card-text>nada aqui!</v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card color="basil" flat>
                <v-card-text>nada aqui!</v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <v-card color="basil" flat>
                    <v-card-text>
                      <v-list class="mt-0 mb-0 pt-0">
                        <v-subheader class="mb-0 pb-0 fs-10pt f-montserrat fw-600" style="max-height: 30px;">
                          Não lidos
                        </v-subheader>
                        <hr class="mt-0 mb-1 pt-0 mx-3">
                        <div class="card py-3 m-2 fs-10pt f-lato fw-300" v-if="notifications.length == 0">
                          Nenhuma notificação encontrada!
                        </div>
                        <div class="" v-for="(item, index) in notifications" :key="index">
                          <v-divider class="mt-0 mb-0 pt-0 pb-0"/>
                        </div>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </div>
                <div class="col-12 col-lg-6">
                  <v-card color="basil" flat>
                    <v-card-text>
                      <v-list class="mt-n2 mb-0 pt-0">
                        <v-subheader class="mb-0 pb-0 fs-10pt f-montserrat fw-600" style="max-height: 30px;">
                          Lidos
                        </v-subheader>
                        <hr class="mt-0 mb-1 pt-0 mx-3">
                        <div class="card py-3 m-2 fs-10pt f-lato fw-300" v-if="notificationsReaded.length == 0">
                          Nenhuma notificação encontrada!
                        </div>
                        <div class="" v-for="(item, index) in notificationsReaded" :key="index">
                          <topmenuitemnotificationitem :item="item"/>
                          <v-divider class="mt-0 mb-0 pt-0 pb-0"/>
                        </div>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
            </v-tab-item>

          </v-tabs-items>
        </div>
      </div>

    </div>
  </div>

</div>
</template>

<script>
import { eventBus } from "@/main.js";
import { rdb, snapshotValToArray, snapshotValToArraySort } from '@/firebasedb.js'

export default {
  name: 'userprofile',
  components: { },
  props: {
  },
  data(){
    return {
      user: {},
      tab: null,
      items: [ 'INTRO', 'ATIVIDADES', 'PROJETOS', 'NOTIFICAÇÕES' ],
      notifications: [],
      notificationsReaded: [],
    }
  },
  created() {
    console.log("userprofile Created");
  },
  mounted() {
    var self = this;
    console.log("userprofile Mounted")
    self.user = self.$session.get('user');

  },
  methods: {


  }
}

</script>

<style scoped>

</style>
